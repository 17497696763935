

import '../scss/app.scss'

import jquery from './jquery'
import validate from './validate'

jquery()
validate()

/* const VIDEO = document.getElementById('video'),
      VIDEO_THUMB = document.getElementById('video_thumb'), */
    const PROTOCOL = window.location.protocol,
      HOSTNAME = window.location.hostname,
      PATHNAME = window.location.pathname
  
	const nav = () => {
	    
	    let navItem = document.getElementsByClassName('nav__link')
	    let mobileWrap = document.getElementById('mobile_menu')
	    let navArr = [].slice.call(navItem)
	    let mobileNav = navArr.map((nav) => {
	        let id = nav.hash
	        return '<a class="nav-mobile__menu_item" href="' + id + '">' + nav.innerText + '</a>'
	    })
	    
	    mobileWrap.innerHTML = mobileNav.join(' ')
	}
	nav()


    const setCanonicalHref = () => {
        let target = document.querySelectorAll("link[rel='canonical']")
            target[0].href = PROTOCOL + '//' + HOSTNAME + PATHNAME
    }
    setCanonicalHref()


	const onFocusChangeColorSvg = () => {
		jQuery('img.form__input_icon').each(function(){
			var $img = jQuery(this);
			var imgID = $img.attr('id');
			var imgClass = $img.attr('class');
			var imgURL = $img.attr('src');
		
			jQuery.get(imgURL, function(data) {
				var $svg = jQuery(data).find('svg');
		
				if(typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID);
				}
				if(typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass+' replaced-svg');
				}
		
				$svg = $svg.removeAttr('xmlns:a');
		
				if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
					$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
				}
		
				$img.replaceWith($svg);
		
			}, 'xml');
		
		});
	}

	onFocusChangeColorSvg()


	/* const ajaxSendData = () => {
        var formph1 = $('#try_form');	
        jQuery.support.cors = true;
            formph1.validate({
            submitHandler: function(formph) {
                var fd = new FormData( formph );
                $.ajax({
                    async: true,
                    url: "./src/mail/send_try.php", 
                    type: "POST",             
                    data: fd,
                    cache: false,  
                    contentType: false,			
                    processData: false,      
                    success: function(data) {
                        if(data == 'done'){
                            // console.log(data);
                            formph.reset();
                        } else {
                            // console.log(data);
                            formph.reset();
                            $('#modal').removeAttr('hidden');
                            $('#modal_subject').text("Processing of your request is in progress right now. Link and credentials to the system will be sent in couple of hours on your email. Lets keep in touch!");
                            // $('#try_response').text('Sent successfully')
                            // setTimeout(()=>{
                            //     $('#try_response').text('Try now')
                            // }, 5500);
                        }
                    },
                    error: function(data){
                        console.log('error');
                        console.log(data);
                        $('#modal').removeAttr('hidden');
                        $('#modal_subject').text("There is an error with processing your request. Contact us in another way");
                    }
                });
                return false;
            },
            rules: {
                try_email: {
                    required: true,
                    email: true
                }
            },
            messages: {
                try_email: {
                    required: "Required field!",
                    email: "Email must be in name@domain.com format"
                },
                
            },
                errorElement : "div",
                focusInvalid: true,
                errorClass: "input_error"
            });
		

		var formph = $('#contacts_form_mobile');	
        formph.validate({
        submitHandler: function(formph) {
            var fd = new FormData( formph );
            $.ajax({
                async: true,
                url: "./src/mail/send_contacts.php", 
                type: "POST",             
                data: fd,
                cache: false,  
                contentType: false,			
                processData: false,      
                success: function(data) {
                    if(data == 'done'){
                        formph.reset();
                    } else {
                        formph.reset();
                        $('#modal').removeAttr('hidden');
                        $('#modal_subject').text("Message was successfully sent");
                        // $('#contacts_response_mobile').text('Sent successfully')
                        // setTimeout(()=>{
                        //     $('#contacts_response_mobile').text('Send Message')
                        // }, 5500);
                    }
                },
                error: function(data){
                    console.log('error');
                    console.log(data);
                    $('#modal').removeAttr('hidden');
                    $('#modal_subject').text("There is a problem with sending message. Please, contact us in another way");
                }
            });
            return false;
        },
        rules: {
			contacts_name: {
				required: true
			},
            contacts_email: {
                required: true,
                email: true
            },
			contacts_text: {
				required: true
			}
        },
        messages: {
			contacts_name: {
				required: "Required field!"
			},
            contacts_email: {
                required: "Required field!",
                email: "Email must be in name@domain.com format"
            },
			contacts_text: {
				required: "Required field!"
			}
        },
            errorElement : "div",
            focusInvalid: true,
            errorClass: "input_error"
        });


        var formph = $('#contacts_form');	
        formph.validate({
        submitHandler: function(formph) {
            var fd = new FormData( formph );
            $.ajax({
                async: true,
                url: "./src/mail/send_contacts.php", 
                type: "POST",             
                data: fd,
                cache: false,  
                contentType: false,			
                processData: false,      
                success: function(data) {
                    if(data == 'done'){
                        // console.log(data);
                        formph.reset();
                    } else {
                        // console.log(data);
                        formph.reset();
                        $('#contacts_response').text('Sent successfully')
                        setTimeout(()=>{
                            $('#contacts_response').text('Send Message')
                        }, 5500);
                    }
                },
                error: function(data){
                    console.log('error');
                    console.log(data);
                }
            });
            return false;
        },
        rules: {
			contacts_name: {
				required: true
			},
            contacts_email: {
                required: true,
                email: true
            },
			contacts_text: {
				required: true
			}
        },
        messages: {
			contacts_name: {
				required: "Required field!"
			},
            contacts_email: {
                required: "Required field!",
                email: "Email must be in name@domain.com format"
            },
			contacts_text: {
				required: "Required field!"
			}
        },
            errorElement : "div",
            focusInvalid: true,
            errorClass: "input_error"
        });
		
	}

	ajaxSendData() */

    $(document).ready(function(){
        setTimeout(function(){
            window.scrollTo(0, 0);
        }, 1);
    });

    const toggleClassToMenu = () => {
        $(document).ready(function() {
            $(window).scroll(function() {
              if ($(document).scrollTop() > 50) {
                $('.header__inner').addClass("colored");
              } else {
                $('.header__inner').removeClass("colored");
              }
            });
            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
                
                $('section').each(function(i) {
                    if ($(this).position().top <= (scrollDistance + 200)) {
                        $('.nav a.active').removeClass('active');
                        $('.nav a').eq(i).addClass('active');
                    }
                });
            }).scroll();
          });
    }
    toggleClassToMenu()


    const scrollToAnchor = () => {
        $(document).ready(function() {
            $("a[href^='#']").click(function (e) {
        	
            	e.preventDefault();
            	var position = $($(this).attr("href")).offset().top
            	let header = $('.header__inner')
                if(window.innerWidth >= 537) {
                    if(header.hasClass('colored')) {
                       position -= 150
                    } else {
                       position -= 195
                    }
                } else if(window.innerWidth <= 537) {
                    if(header.hasClass('colored')) {
                        position -= 45
                     } else {
                        position -= 45
                     }
                }
            	
            	let coord = {
            	    scrollTop: position
            	}
            	$("body, html").animate(coord);
            
            })
        })
    }
    scrollToAnchor()


    /* const videoContainer = () => {
        let start = document.getElementById('video_start'),
            close = document.getElementById('video_close'),
            watch = document.getElementById('video_watch')
            close.hidden = true
            VIDEO.hidden = true
            
            start.onclick = () => {
                close.hidden = false
                start.hidden = true
                VIDEO_THUMB.hidden = true
                VIDEO.hidden = false
                VIDEO.src += "?autoplay=1"
            }

            close.onclick = () => {
                close.hidden = true
                start.hidden = false
                VIDEO_THUMB.hidden = false
                VIDEO.hidden = true
                VIDEO.src += "?autoplay=0"
            }

            watch.onclick = () => {
                close.hidden = false
                start.hidden = true
                VIDEO_THUMB.hidden = true
                VIDEO.hidden = false
                VIDEO.src += "?autoplay=1"
            }
    }
	videoContainer() */


    const onHandleClickOpenMenu = () => {
        let buttonOpen = document.getElementById('menu_open'),
            buttonClose = document.getElementById('menu_close'),
            menu = document.getElementById('menu_wrap'),
            menuItems = document.getElementsByClassName('nav-mobile__menu_item')
            menuItems = [].slice.call(menuItems)

        menu.hidden = true
        
        buttonOpen.onclick = () => {
            menu.hidden = false
            console.log('test')
        }

        buttonClose.onclick = () => {
            menu.hidden = true
            console.log('test')
        }

        menuItems.map(item => {
            item.onclick = () => {
                menu.hidden = true
            }
        })
    }
    onHandleClickOpenMenu()

    /* const onModal = () => {
        const buttonClose = document.getElementById('close_button'),
              modal = document.getElementById('modal')

        modal.hidden = true;

        buttonClose.onclick = () => {
            modal.hidden = true;
        }
    }
    onModal() */
/* 
    const videoResize = () => {
       VIDEO.attributes.width.value = VIDEO_THUMB.clientWidth
       VIDEO.attributes.height.value = VIDEO_THUMB.clientWidth / 1.77
    }
	videoResize() */